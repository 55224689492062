// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAykErfQvKFq54f1XcqJuYWejSaW7_fmaY",
  authDomain: "i-care-cc921.firebaseapp.com",
  databaseURL: "https://i-care-cc921.firebaseio.com",
  projectId: "i-care-cc921",
  storageBucket: "i-care-cc921.appspot.com",
  messagingSenderId: "475050172015",
  appId: "1:475050172015:web:1cba5a28d43487c971b79a",
  measurementId: "G-958RBQC9WX",
};

try {
  firebase.initializeApp(firebaseConfig);
} catch (e) {
  console.log(e);
}
